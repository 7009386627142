@import '../../common/styles/functions.scss';
@import '../../common/styles/variables.scss';
@import '../../common/styles/icons.scss';
@import '../../common/styles/tables.scss';
@import '../../common/styles/typography.scss';
@import '../../common/styles/mixins.scss';

body {
  background-color: $color-background;
  overflow-y: hidden;
}

.App {
  text-align: center;
}

.help-table {
  margin: 0;
  padding: 0;
}

.next-to-sidebar {
  margin-left: 52px !important;
}

div {
  .bb-tooltip {
    td,
    th {
      font-size: $font-sm;
    }
  }
}

div {
  .bb-tooltip {
    td,
    th {
      font-size: $font-sm;
    }
  }
}

.App-intro {
  font-size: large;
}

.MuiAppBar-colorPrimary,
.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickersDay-daySelected,
.MuiPickerDTTabs-tabs {
  background-color: $color-LelyRed !important;
}

.MuiPickersClockPointer-thumb {
  border-color: $color-LelyRed !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}

.MuiTableRow-root .MuiButton-root {
  opacity: $opacity-default;
}

.MuiTableRow-root .MuiButton-root:hover {
  opacity: $opacity-hover;
}

.pointer {
  cursor: pointer;
}

.btn-filter {
  margin-right: 5px !important;
}

h3.selected-farm-header {
  color: $text-black-color;
  font-weight: bold;
}

h4.selected-farm-header {
  font-weight: normal;
  margin-top: -20px;
  margin-bottom: 16px;
}

.selected-farm-header {
  padding-left: 7px;
}

.custom-datepicker {
  position: relative;

  .MuiFormLabel-root {
    &.Mui-focused {
      color: $color-LelyBlack;
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

// hides default mopionion feedback button
#surveyContent {
  display: none !important;
}

#surveySliderBtn {
  visibility: hidden !important;
}

.underline-tag {
  height: 1px;
  background: $color-dairy;
  display: block;
  width: 100%;
  height: 0.03125rem;
  content: '';
  margin: 0.5rem auto;
}
