@import '../../styles/variables.scss';
@import '../../styles/functions.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.sidebar-fixed-wrapper {
  position: fixed;
  z-index: $zindex-sidebar;
  bottom: 0;
  float: left;
  margin-right: 20px;
  width: toRem(36);
  overflow: hidden;
}

.sidebar {
  &__bar {
    @include flexColumnSpaceBetween();
    left: 0px;
    top: 0px;
    padding: toRem(4);
    background-color: $color-LelyRed;
    border-radius: 0 $border-radius-medium 0 0;
    height: 100%;
    transition: $transition;
    overflow: hidden;
  }
  &__navlist {
    list-style: none;
    margin: 0px;
    padding: 0px;
    span {
      flex: 1;
    }
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: toRem(2);
  }

  &__navbtn {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-items: center;
    padding: toRem(4);
    margin: 0;
    line-height: $font-base-lh;
    text-decoration: none;
    cursor: pointer;
    border-radius: toRem(4);
  }
  &__navitemicon {
    color: $color-LelyWhite;
    stroke: $color-LelyWhite;
    margin: 0px;
    line-height: 0;
    opacity: 0.8;
    width: toRem(18);
    height: toRem(18);
    vertical-align: text-bottom;
    &.lely-icon-person {
      background-color: #ffffff;
      border-radius: 50%;
      padding: toRem(1);
      opacity: 1 !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #646060;
      stroke-width: 0;
    }
  }
  &__navtitle {
    position: relative;
    display: none;
    left: toRem(10);
    padding: 0;
    font-weight: $font-bold;
    color: $color-LelyWhite !important;
    opacity: 0.8;
    transition: $transition;
  }
  &__navtitle:hover {
    transition-delay: 0.8s;
  }
  &__navitem {
    &:active,
    &.active,
    &:focus,
    &:hover {
      background-color: $brand-primary-dark;
      color: $brand-primary;
      border-radius: 0.25rem;
    }
    @include flexRow();
    flex-wrap: nowrap;
    gap: toRem(8);
    margin: 0.6rem 0.25rem;
    &:first-child {
      margin: 0.3rem 0.25rem;
    }
  }
}

.app-sidebar {
  transition: width 0.5s ease;
  @include translatezhack;
}

.app-sidebar.hoverable:hover {
  width: 200px;
  transition-delay: 0.5s;
}

.sidebar__navtitle {
  display: inline-block;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0s ease-in-out 0.5s;
  overflow: hidden;
}

.app-sidebar:hover.hoverable .sidebar__navtitle {
  opacity: 1;
}

.down {
  position: absolute;
  bottom: 0.5rem;
  overflow: hidden;
  padding-bottom: 0;
  right: 0;
  left: 0.25rem;
  & > a {
    padding-left: 0.35rem;
  }
}

.help-button {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0.25rem;
}
.bordertop {
  border-top: 1px solid rgba(203, 198, 197, 0.4);
}
.borderdown {
  padding-top: 0;
  border-bottom: 1px solid rgba(203, 198, 197, 0.4);
}
