/* stylelint-disable */
$color-LelyRed: #c30a14;
$color-LelyRedDark: #73130e;
$color-LelyWhite: #ffffff;
$color-GrayDairy: #dedbd6;
$color-GrayDairyLight: #efedea;
$color-GrayDairyDarker: #766e68;
$color-GrayDairyLighter: #f7f6f4;
$color-RecommendedTextColor: #333333;
$color-LelyBlack: #000;
$color-RecommendedIconColor: #766e68;
$color-BrandSuccess: #73bd07;
$color-BackUpSuccess: #390;
$color-BrandWarning: #ff7921;
$color-BrandDanger: #fd3535;
$color-BackUpError: #f50d19;
$color-BrandInfo: #ffc700;
$color-LelyBerry: #4d33dc;
$color-LelyPetrol: #0099bb;
$color-LelyCattle: #c56a28;
$color-dairy: #dedbd6;
$color-gray: #333333;
$color-gray-lighter: #777777;
$color-gray-light: #cfcbc3;
$color-gray-arrow: #c3c0bc;
$color-search-border: #86070e;
$brand-primary-dark: $color-LelyRedDark;
$brand-primary: $color-LelyRed;
$text-link-color: $color-LelyRedDark;
$text-black-color: #171414;
$text-onDark-color: rgba(255, 255, 255, 0.9);
$text-light-color: #646060;
$color-background: #f7f3f2;
$color-border: #cbc6c5;
$color-title-text: #565151;
$color-filter-button-text: #121212;
$color-critical-level-event: #e30013;
$color-non-critical-level-event: #ef6c00;

/* Pie Chart Colors SCSS HEX */
$red-salsa: #f94144ff;
$maximum-blue-purple: #9ba2ffff;
$fandango: #b30089ff;
$yellow-orange-color-wheel: #f8961eff;
$maize-crayola: #f9c74fff;
$yellow-green-crayola: #cfe795ff;
$pistachio: #90be6dff;
$zomp: #43aa8bff;
$medium-turquoise: #55dde0ff;
$cg-blue: #277da1ff;
$blue-crayola: #3777ffff;
$rose-pink: #f059bbff;
$light-coral: #f77977ff;
$olive-drab-3: #789e31ff;
$spring-green: #3bf576ff;
$inchworm: #afe846ff;

$font-DefaultFont: Ubuntu;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

$font-DefaultFontStyle: normal;

$opacity-default: 0.25;
$opacity-hover: 1;

$color-border: $color-dairy;
$color-text: $color-gray;
$border-radius-small: 4px;
$border-radius-medium: 20px;
$border-radius-large: 10px;
$border-radius: $border-radius-small;
$box-shadow: 0 2px 5px 0 rgba($color-dairy, 0.4);

/* Animations */
$ease-out: cubic-bezier(0.36, 0.03, 0.28, 0.99);
$ease-in: cubic-bezier(0.46, 0.09, 0.82, 0.73);
$ease-back-end: cubic-bezier(0.35, 0.01, 0.44, 1.75);
$ease-back-start: cubic-bezier(1.54, 0.66, -0.08, 0.49);

$transition-out: 0.5s cubic-bezier(0.36, 0.03, 0.28, 0.99);
$transition-out-slow: 1s cubic-bezier(0.36, 0.03, 0.28, 0.99);
$transition-out-fast: 0.15s cubic-bezier(0.36, 0.03, 0.28, 0.99);

$transition-in: 0.5s cubic-bezier(0.46, 0.09, 0.82, 0.73);
$transition-in-slow: cubic-bezier(0.46, 0.09, 0.82, 0.73);
$transition-in-fast: 0.15s cubic-bezier(0.46, 0.09, 0.82, 0.73);

$transition: all $transition-out;
$transition-all-out: all $transition-out;
$transition-all-in: all $transition-in;

$default-transition: all 1s ease;
$fast-transition: all 0.3s ease;

@mixin translatezhack {
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

$zindex-sidebar: 999999999;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1440px,
  xl: 1640px,
);

$domain-modal-header-height: 59px;

$search-input-width: 400px;
$search-input-height: 40px;
$search-input-font-size: 14px;
$search-input-border-radius: 4px;

$common-margin: 8px;
$vector-timeline-height: 26px;
$header-height: 42px;
$spacing-wrapper-margins: 32px;
$height-overview-page: $header-height + $spacing-wrapper-margins;
$sidebar-width: 54px;
$data-expolorer-margin: 8px;
$data-expolorer-scroll: 8px;
$data-expolorer-indent: 28px;
$spacing-between-containers: 8px;
$ade-table-header: 40px;
$ade-table-footer: 47px;
$vde-apply-button-container: 128px;
$ade-left-menu: 256px;
$side-bar: 45px;
$farms-page-search-bar: 52px;
$settings-search-bar: 60px;
$settings-table-header-and-borders: 30px;
$farms-table-header-and-borders: 34px;
$ade-page-spacings: $ade-left-menu + $side-bar +
  (3 * $spacing-between-containers); // 5 - margins between the containers, the scrollbar width and margin between the scrollbar and the end of the page
$ade-events-table-indent: $header-height + 2 * $spacing-between-containers +
  $ade-table-header + $ade-table-footer;
$vde-menu-height: 9 * $spacing-between-containers + $vde-apply-button-container +
  $header-height;
$aggregation-window-top-margin: 2px;
// Font sizes
$fs-m: 13px;
$farms-list-table-ident: $header-height + 3 * $spacing-between-containers +
  $farms-page-search-bar + $farms-table-header-and-borders;
$settings-page-table-ident: $header-height + 2 * $spacing-between-containers +
  $settings-search-bar + $settings-table-header-and-borders;
$aggregation-thumb-width: 1rem;
