@import './typography.scss';

.sorting-icons {
  border: none;
  background-color: transparent !important;
  height: auto;
  cursor: pointer;
}

@mixin tableHead() {
  @include baseCard();
  border-bottom: toRem(1) solid $color-gray-light;
  width: fit-content;
  box-sizing: border-box;
  font-size: $font-sm;
  position: sticky;
  z-index: 999;
  height: toRem(32);
  top: 0;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  // hide scrollbar on firefox, but keep the functionality
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  tr {
    flex-grow: 1;
    display: flex;

    th {
      flex-grow: 1;
      flex-basis: 0;
      display: table-cell;
      padding-left: toRem(8);
    }
  }

  .table-row-container {
    padding: toRem(8) toRem(16);

    .table-row-container-inner {
      @include flexItemsCenter();
    }
  }

  .btn-sort {
    display: flex !important;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .lely-icon-caret-down {
    line-height: toRem(6) !important;
    color: $color-GrayDairy;

    &.active {
      color: $color-RecommendedIconColor;
    }

    &.desc {
      transform: rotate(180deg);
    }
  }

  .lely-icon-caret-down:before {
    font-size: $font-lg;
    line-height: toRem(6);
  }
}

@mixin tableMain() {
  @include flexRowItemsCenterContentSpaceBetween();
  background-color: white;
  padding: $spacing-between-containers toRem(12);
  top: toRem(8);
  position: sticky;
  z-index: 1000;
  margin: 0;
  border: toRem(1) solid $color-gray-light;

  @media all and (min-width: 1280px) {
    max-width: 100%;
  }

  .MuiSelect-select {
    width: toRem(200);
  }
}

@mixin tableBody() {
  display: block;

  tr {
    min-width: 100%;
    border-bottom: toRem(1) solid $color-gray-light;
    flex-grow: 1;
    display: flex;

    td {
      max-width: unset;
      padding: toRem(8) toRem(16);

      vertical-align: top;
      flex-basis: 0;
      flex-grow: 1;
      display: table-cell;
    }
  }
}

@mixin tableList() {
  border: toRem(1) solid $color-gray-light;
  border-top: 0;
  display: block;
  table-layout: fixed;
  overflow: hidden;
  border-spacing: 0 toRem(1);
  margin-bottom: toRem(0);
  white-space: nowrap;
}

@mixin tableTabsContainer() {
  .tabs-container {
    position: relative;

    .MuiButtonBase-root-MuiTab-root {
      min-height: auto;
    }

    .MuiTabs-root {
      min-height: auto;

      .MuiTab-root {
        min-height: auto;
        padding: 0 $spacing-between-containers;
        font-size: $font-base;
        min-width: auto;

        .MuiTab-wrapper {
          text-transform: none;
          font-family: Ubuntu, sans-serif !important;
          font-weight: 400;
        }

        &.Mui-selected > .MuiTab-wrapper {
          font-weight: bold;
        }
      }
    }

    .MuiTabs-scroller {
      position: inherit;
    }

    .MuiTabs-indicator {
      background-color: $color-LelyRed;
      position: absolute;
      bottom: -8px;
    }
  }
}

@mixin vectorTableSortingBtn() {
  .sorting-icons {
    border: none;
    background-color: transparent !important;
    width: toRem(16);
    display: inline-flex;
    svg:first-child {
      top: -1px !important;
    }
    svg:last-child {
      bottom: -1px !important;
    }
  }

  .lely-icon-caret-down {
    line-height: toRem(10) !important;
    color: $color-GrayDairy;

    &.active {
      color: $color-RecommendedIconColor;
    }
    &.desc {
      transform: rotate(180deg);
    }
  }

  .lely-icon-caret-down:before {
    font-size: $font-xl;
    line-height: toRem(4);
  }
}

@mixin settingsBackupTableSortingBtn() {
  .sorting-icons {
    border: none;
    background-color: transparent !important;
    width: toRem(16);
    display: inline-flex;
    svg:first-child {
      top: 8px !important;
    }
    svg:last-child {
      bottom: 8px !important;
    }
  }

  .lely-icon-caret-down {
    line-height: toRem(10) !important;
    color: $color-GrayDairy;

    &.active {
      color: $color-RecommendedIconColor;
    }
    &.desc {
      transform: rotate(180deg);
    }
  }

  .lely-icon-caret-down:before {
    font-size: toRem(20);
    line-height: toRem(4);
  }
}
