@import '../../common/styles/variables.scss';
@import '../../common/styles/functions.scss';
@import '../../common/styles/typography.scss';
@import '../../common/styles/mixins.scss';

.welcomeWrapper {
  @include flexColumnItemsCenter();
}

.top-navigation-fixed-wrapper {
  // height: $header-height;
  @include flexRowItemsCenterContentSpaceBetween();
  width: 100%;
}

.top-navigation {
  $self: &;
  @include flexRowItemsCenterContentSpaceBetween();
  // height: $header-height;
  padding: toRem(6) toRem(8);
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  padding-left: $sidebar-width;
  background-color: $color-LelyWhite;
  box-shadow: 0 toRem(2) toRem(5) 0 rgba(222, 219, 214, 0.4);
  border-bottom: 1px solid #cbc6c5;

  &__inner {
    @include flexRowItemsCenterContentSpaceBetween();
    height: 100%;
  }
  &__detail {
    display: none;
    justify-content: space-between;
    align-items: center;
    .heading {
      position: relative;
      top: -1px;
      margin: 0px;
      flex-direction: column;
    }
  }
  .search-container {
    justify-content: center;
    width: 100%;
  }
  &__search {
    @include flexItemsCenterContentCenter();
    position: relative;
    top: 1px;
    width: 24px;
    height: 24px;
    text-decoration: none;

    i[class^='lely-icon-'],
    i[class*='lely-icon-'] {
      margin-right: 0;
    }
  }
  &__logo {
    height: 25px;
    @include flexItemsCenter();
    justify-content: flex-start;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    svg {
      width: 34px;
      height: 22px;
    }
  }
  &__logotext {
    color: $color-RecommendedTextColor;
    white-space: nowrap;
    font-size: $font-base;
  }
  &__logosvg {
    margin-right: toRem(6);
  }
  &__linkback {
    position: relative;
    display: inline-block;
    // background-image: url('#{$assetPath}/svg/icon-caret-left.svg');
    background-repeat: no-repeat;
    background-position: center center;
    width: 18px;
    height: 24px;
  }
  &__btns {
    @include flexRowItemsCenterContentCenter();
    gap: toRem(12);

    .btn {
      @include baseCardWithBorder();
      @include flexItemsCenter();
      justify-content: center;
      width: toRem(30);
      height: toRem(30);
      padding: 0;
      text-align: center;
      transition: all 0.5s cubic-bezier(0.36, 0.03, 0.28, 0.99);
      box-shadow: 0 2px 5px 0 rgba(222, 219, 214, 0.4);
      i[class^='lely-icon-'] {
        color: $color-GrayDairyDarker;
        margin: 0px;
        line-height: 36px;
        font-weight: $font-regular;
        display: inline-block;
        transition: all 0.5s cubic-bezier(0.36, 0.03, 0.28, 0.99);
        box-sizing: border-box;
        padding: 2px 0 0 0;
        margin: 0 auto;
        box-sizing: border-box;
        transform: translateY(2px);
        font-size: toRem(18);
      }
      &:active,
      &.active,
      &:focus,
      &:hover {
        background-color: $color-GrayDairyLight;
        color: $color-GrayDairyDarker;
        box-shadow: 0 0 0 3px rgba(222, 219, 214, 0.6);
        i[class^='lely-icon-'] {
          transition: all 0.5s cubic-bezier(0.36, 0.03, 0.28, 0.99);
          -webkit-transform: translateY(2px);
          transform: translateY(2px);
          display: inline-block;
        }
      }
    }
  }
  &__popper {
    padding: 15px 25px;
    text-align: center;
  }
  .mr-2 {
    margin-right: 1rem;
  }
  &--sub {
    #{$self}__inner {
      display: none;
    }
    #{$self}__detail {
      display: flex;
    }
  }
}

.user-dialog-wrapper {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    width: 400px;
    height: 567px;
  }

  .user-dialog-title {
    background: #c30a14;
    color: #ffffff;
    padding: 14px 16px;
    h2 {
      padding: 0px;
      font-style: normal;
      font-weight: 300;
      font-size: 25px;
    }
  }

  .user-dialog-sub-wrapper {
    @include flexColumnSpaceBetween();
    height: 100%;
    padding: 25px 35px;

    .user-dialog-container {
      padding: 0px;
      margin-bottom: 20px;

      .user-dialog-unit {
        .MuiFormGroup-root.radio-group {
          @include flexRow();
          width: 65%;
          padding: 0;
        }
      }

      .user-dialog-dropdown {
        padding: 0px;
        justify-content: flex-start;
        div {
          margin: 0px;
          width: 100%;
          .MuiSelect-nativeInput {
            width: 97%;
          }
          .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
            padding-left: 20px;
          }
        }
      }

      .user-dialog-title-text {
        color: #766e68;
        margin: 0px;
        display: flex;
        margin-bottom: 12px;

        svg {
          display: flex;
        }
      }

      .user-dialog-logout-action-wrapper {
        justify-content: space-between;
        padding: 0px;
        align-items: center;

        button {
          text-transform: none;
        }

        .user-dialog-user-name {
          color: #766e68;
          margin: 0px;
        }
      }
    }

    .user-dialog-buttons {
      padding: 0px;

      .user-cancel {
        background-color: white !important;
        color: #766e68 !important;
        text-transform: none;
        margin-right: 15px;

        span {
          &:first-child {
            border-bottom: 1px solid #766e68;
          }
        }
      }
      .user-save {
        text-transform: none;
      }
    }
  }
}
