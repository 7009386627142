@import '../../common/styles/variables.scss';
@import '../../common/styles/typography.scss';
@import '../../common/styles/mixins.scss';

.online-state,
.offline-state {
  display: inline-block;
  font-size: $font-base;
  @include flexRowItemsCenter();
  white-space: nowrap;
  height: 100%;

  svg {
    vertical-align: top;
    margin-right: 5px;
  }
}
.offline-state {
  color: $color-RecommendedIconColor;

  span {
    padding-left: 8px;
  }
}

.online-state {
  color: $color-BrandSuccess;
  span {
    padding: 0;
  }
  svg {
    vertical-align: sub;
    font-size: $font-base;
  }
}
