@import '../../common/styles/functions.scss';

@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'),
    url('./fonts/noto\ sans/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansBold';
  src: local('NotoSans'),
    url('./fonts/noto\ sans/NotoSans-Bold.ttf') format('truetype');
}

$font-xs: toRem(8);
$font-sm: toRem(10);
$font-base: toRem(12);
$font-md: toRem(14);
$font-lg: toRem(16);
$font-xl: toRem(18);
$font-h4: toRem(22);
$font-h5: toRem(20);
$font-h6: toRem(18);
$font-h7: toRem(16);
$font-h8: toRem(14);
$font-title: toRem(42);

$font-sm-lh: toRem(16);
$font-base-lh: toRem(18);
$font-md-lh: toRem(20);
$font-lg-lh: toRem(22);
$font-xl-lh: toRem(24);
$font-h4-lh: toRem(28);
$font-h5-lh: toRem(26);
$font-h6-lh: toRem(26);
$font-h7-lh: toRem(22);
$font-h8-lh: toRem(20);
$font-title-lh: toRem(48);

$default-text-color: #171414;
$sense-font: 'NotoSans';
$sense-font-bold: 'NotoSansBold';
$default-font: 'Ubuntu';

.sense-font {
  color: $default-text-color;
  font-family: $sense-font;
}
.default-font {
  color: $default-text-color;
  font-family: $default-font;
}

.fs--b-title {
  @extend .sense-font;
  line-height: $font-title-lh;
  font-size: $font-title;
  font-weight: 700;
}

.fs--b-xs {
  @extend .sense-font;
  line-height: $font-xs;
  font-size: $font-xs;
  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--b-sm {
  @extend .sense-font;
  line-height: $font-sm-lh;
  font-size: $font-sm;

  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--b-base,
.MuiTypography-root {
  @extend .sense-font;
  line-height: $font-base-lh;
  font-size: $font-base;

  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--b-base {
  @extend .sense-font;
  line-height: $font-base-lh;
  font-size: $font-base;

  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--b-md {
  @extend .sense-font;
  line-height: $font-md-lh;
  font-size: $font-md;

  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--b-lg {
  @extend .sense-font;
  line-height: $font-lg-lh;
  font-size: $font-lg;

  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--b-xl {
  @extend .sense-font;
  line-height: $font-xl-lh;
  font-size: $font-xl;

  &--bold {
    font-family: $sense-font-bold;
    font-weight: bold;
  }
}

.fs--h4 {
  @extend .default-font;
  line-height: $font-h4-lh;
  font-size: $font-h4;
  font-weight: normal;
}

.fs--h5 {
  @extend .default-font;
  line-height: $font-h5-lh;
  font-size: $font-h5;
  font-weight: normal;
}

.fs--h5--bold {
  @extend .fs--h5;
  font-weight: 700;
}
.fs--h6 {
  @extend .default-font;
  line-height: $font-h6-lh;
  font-size: $font-h6;
  font-weight: normal;
}
.fs--h6--bold {
  @extend .fs--h6;
  font-weight: 700;
}

.fs--h7 {
  @extend .default-font;
  line-height: $font-h7-lh;
  font-size: $font-h7;
  font-weight: normal;
}

.fs--h7--bold {
  @extend .fs--h7;
  font-weight: 700;
}

.fs--h8 {
  @extend .default-font;
  line-height: $font-h8-lh;
  font-size: $font-h8;
  font-weight: normal;
}

.fs--h8--bold {
  @extend .fs--h8;
  font-weight: 700;
}

.fs--d-header {
  @extend .default-font;
  line-height: $font-base-lh;
  font-size: $font-base;
}

.fs--d-header--bold {
  @extend .fs--d-header;
  font-weight: 700;
}

.fs--d-subheader {
  @extend .sense-font;
  line-height: $font-base-lh;
  font-size: $font-base;
  font-style: italic;
}

.fs--d-subheader--bold {
  @extend .fs--d-subheader;
  font-weight: 700;
}

.fs--d-base {
  @extend .sense-font;
  line-height: $font-base-lh;
  font-size: $font-base;
}
.fs--d-base--bold {
  @extend .fs--d-base;
  font-weight: 700;
}
.fs--d-md-default {
  @extend .default-font;
  font-weight: 300;
  line-height: $font-md-lh;
  font-size: $font-md;
}
.fs--d-base-default {
  @extend .default-font;
  font-weight: 400;
  line-height: $font-base-lh;
  font-size: $font-base;
}
.fs--d-sm {
  @extend .sense-font;
  line-height: $font-sm-lh;
  font-size: $font-sm;
}
.fs--d-sm--bold {
  @extend .fs--d-sm;
  font-weight: 700;
}
