@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/mixins.scss';

.settings-breadcrumb-container {
  @include flexItemsCenter();
  width: 100%;
  height: 100%;
  nav {
    width: auto;
  }

  & .MuiTab-root {
    font-family: $default-font, sans-serif;
  }
}

nav {
  @include flexItemsCenter();
  height: 100%;
  width: 100%;

  &[aria-label='breadcrumbsNav'] {
    .breadcrumb {
      @include flexRowItemsCenter();
      height: 100%;
      padding: 0;
      margin: 0;
      background: none;
      border-radius: 0;
      .breadcrumb-item {
        @include flexItemsCenter();
        height: 100%;
        padding-left: 0;
        margin-left: toRem(18);
        font-size: $font-base;
        &:before {
          content: '/';
          display: inline-block;
          padding-right: 0;
          margin-right: 18px;
          color: $color-border;
          font-weight: $font-regular;
        }

        &--icon {
          position: relative;
          top: 2px;
          &.breadcrumb-item {
            a {
              text-decoration: none;
            }
          }
        }
        &.active {
          a {
            @include flexItemsCenter();
            position: relative;
            pointer-events: none;
            text-decoration: none;
            height: 75%;

            &::after {
              content: '';
              position: absolute;
              bottom: toRem(-8);
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #c30a14;
            }
          }
        }
        a {
          color: $default-text-color;
          margin-bottom: 0px;
          text-decoration: none;
          &:hover,
          &:focus,
          &:active {
            i[class^='lely-icon-'],
            i[class*=' lely-icon-'] {
              color: $default-text-color;
            }
          }
          i[class^='lely-icon-'],
          i[class*=' lely-icon-'] {
            margin-right: 0;
            transition: $transition-in-fast;
          }
        }
      }
    }
  }
}
