@import '../../common/styles/variables.scss';
@import '../../common/styles/functions.scss';
@import '../../common/styles/typography.scss';

.btn-login {
  text-transform: none !important;
}

form {
  .title {
    color: $color-LelyWhite;
    font-weight: 300;
    padding: toRem(24) 0;
    width: toRem(600);
  }
}

.header {
  color: $color-LelyWhite;
  letter-spacing: toRem(2);
  font-size: $font-base;
  font-weight: 500;
}

.delimeter {
  margin: toRem(24) 0;
  font-size: $font-sm;
  font-style: normal;
  font-weight: 700;
  line-height: toRem(15);
  text-transform: uppercase;
  color: $color-LelyWhite;
}

.delimeter:after {
  content: ' ';
  height: toRem(1);
  width: toRem(544);
  background: $color-LelyWhite;
  position: absolute;
  margin-top: toRem(7);
  margin-left: toRem(20);
}
.LoginPage {
  label.MuiFormLabel-root {
    -webkit-transform: translate(toRem(12), toRem(7)) scale(0.75);
    -moz-transform: translate(toRem(12), toRem(7)) scale(0.75);
    -ms-transform: translate(toRem(12), toRem(7)) scale(0.75);
    transform: translate(toRem(12), toRem(7)) scale(0.75);
    transition: none;
  }

  input.MuiInputBase-input {
    padding-top: toRem(22);
    padding-bottom: toRem(6);
    font-size: $font-base;
  }
}
