@mixin flexItemsCenter() {
  display: flex;
  align-items: center;
}

@mixin flexContentCenter() {
  display: flex;
  justify-content: center;
}

@mixin flexContentSpaceBetween() {
  display: flex;
  justify-content: space-between;
}

@mixin flexItemsCenterContentCenter() {
  @include flexColumnItemsCenter();
  justify-content: center;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin flexColumnItemsEndContentAround() {
  @include flexColumn();
  align-items: flex-end;
  justify-content: space-around;
}

@mixin flexColumnItemsSpaceAround() {
  @include flexColumn();
  justify-content: space-around;
}

@mixin flexColumnSpaceBetween() {
  @include flexColumn();
  justify-content: space-between;
}

@mixin flexColumnItemsCenter() {
  @include flexColumn();
  align-items: center;
}

@mixin flexColumnItemsCenterContentStart() {
  @include flexColumn();
  justify-content: flex-start;
}

@mixin flexRow() {
  display: flex;
  flex-direction: row;
}

@mixin flexRowItemsCenter() {
  @include flexRow();
  align-items: center;
}
@mixin flexRowSpaceBetween() {
  @include flexRow();
  justify-content: space-between;
}
@mixin flexRowItemsCenterContentSpaceAround() {
  @include flexRowItemsCenter();
  justify-content: space-around;
}

@mixin flexRowItemsCenterContentCenter() {
  @include flexRowItemsCenter();
  justify-content: center;
}

@mixin flexRowItemsStartContentStart() {
  @include flexRow();
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flexRowItemsCenterContentSpaceBetween() {
  @include flexRowItemsCenter();
  justify-content: space-between;
}

@mixin flexRowCenter() {
  @include flexRow();
  place-items: center;
}
