@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/functions.scss';

.user-dialog-wrapper {
  border-top-left-radius: toRem(8);
  border-top-right-radius: toRem(8);

  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    width: toRem(400);
    height: toRem(567);
  }

  .user-dialog-title {
    background: #c30a14;
    color: #ffffff;
    padding: toRem(14) toRem(16);
    h2 {
      padding: 0;
      font-style: normal;
      font-weight: 300;
      font-size: toRem(25);
    }
  }

  .user-dialog-sub-wrapper {
    height: 100%;
    padding: toRem(10) toRem(20);
    @include flexColumnSpaceBetween();

    .MuiButtonBase-root {
      font-size: $font-base;
    }

    .user-dialog-container {
      padding: 0;
      margin-bottom: toRem(20);

      .user-dialog-unit {
        .MuiFormGroup-root.radio-group {
          @include flexRow();
          width: 65%;
          padding: 0;
        }
      }

      .user-dialog-dropdown {
        padding: 0;
        justify-content: flex-start;
        div {
          margin: 0;
          width: 100%;
          .MuiSelect-nativeInput {
            width: 97%;
          }
          .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
            padding-left: toRem(20);
          }
        }
      }

      .user-dialog-title-text {
        display: flex;
        gap: toRem(8);
        align-items: center;
        color: #766e68;
        font-weight: 300;

        svg {
          display: flex;
        }
      }

      .under-title-msg {
        margin: 0;
      }

      .user-dialog-logout-action-wrapper {
        justify-content: space-between;
        padding: 0;
        align-items: center;

        button {
          text-transform: none;
        }

        .user-dialog-user-name {
          color: #766e68;
          margin: 0;
        }
      }
    }

    .user-dialog-buttons {
      padding: 0;

      .user-cancel {
        background-color: white !important;
        color: #766e68 !important;
        text-transform: none;
        margin-right: toRem(15);

        span {
          &:first-child {
            border-bottom: toRem(1) solid #766e68;
          }
        }
      }
      .user-save {
        text-transform: none;
      }
    }
  }
}

.user-settings {
  .user-dialog-content {
    overflow-y: unset;
  }
}
