/* stylelint-disable */
@import './variables.scss';
@import './functions.scss';
@import './flexMixins.scss';
@import './tableMixins.scss';

@mixin baseCard() {
  background-color: $color-LelyWhite;
  border-radius: toRem(4);
}

@mixin baseCardWithBorder() {
  @include baseCard();
  border: toRem(1) solid $color-border;
}

@mixin baseContainer() {
  @include baseCard();
  width: 100%;
}

@mixin overflowY() {
  overflow-y: auto;
}

@mixin baseContainerWithBorder() {
  @include baseCardWithBorder();
  @include baseContainer();
}
