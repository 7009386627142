@import './variables.scss';
@import './functions.scss';
@import './typography.scss';
@import './flexMixins.scss';
@import './mixins.scss';

.data-explorer-page {
  height: calc(100vh - #{$header-height}) !important;
  padding: toRem(8) !important;
  padding-left: toRem(50) !important;
  overflow: hidden;
  box-sizing: border-box;

  .bb-tooltip-container {
    tbody tr {
      td,
      th {
        font-size: $font-sm;
      }
    }
  }
}

.container {
  margin: 8px;
}

ul li.MuiButtonBase-root {
  font-size: $font-base;
}

.MuiAutocomplete-option {
  font-size: $font-base;
}

.page-wrapper-with-scrollbar {
  height: calc(100vh - #{$height-overview-page});
  overflow-y: auto;
  margin: 0.5rem;
  padding: 0.5rem;
  padding-left: 0;
}

.common-filter-menu {
  &__bg-color {
    @include baseCard();
  }
  &__border {
    @include baseCardWithBorder();
    padding: 1rem;
  }
  &__flex-column {
    @include flexColumn();
  }
  &__overflow-y {
    @include overflowY();
  }
}
