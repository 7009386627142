@import './variables.scss';
@import './functions.scss';
@import './typography.scss';
@import './mixins.scss';

.table {
  @extend .fs--b-base;
  border-collapse: separate; //needed for borders
  border-spacing: 0 toRem(2);
  position: relative; //relative is needed for btn-collapse position in tables to create bounding box
  width: 100%;

  &.farm-list,
  &.robot-list,
  &.backup-settings-list {
    .sticky-header {
      background-color: white;
      border-bottom: toRem(1) solid $color-gray-light;
      border-radius: 0;
    }

    .table-row-container {
      min-width: toRem(65);
      background-color: #fff;
    }

    .table-row-container-inner {
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      span::first-letter {
        text-transform: uppercase;
      }

      .btn-sort {
        display: flex !important;
        flex-flow: column nowrap;
        justify-content: center;
      }

      .lely-icon-caret-down {
        line-height: toRem(6) !important;
        color: $color-GrayDairy;

        &.active {
          color: $color-RecommendedIconColor;
        }
        &.desc {
          transform: rotate(180deg);
        }
      }

      .lely-icon-caret-down:before {
        font-size: $font-xl;
        line-height: toRem(7);
      }
    }
  }
  &.farm-list,
  &.robot-list {
    cursor: pointer;
  }
  .table-level {
    font-family: $sense-font;
    font-style: normal;
    font-weight: bold;
    font-size: $font-sm;
    line-height: $font-sm-lh;
    @include flexItemsCenter();
    color: $color-LelyWhite;
    width: fit-content;
    border-radius: toRem(10);
    padding: 0 toRem(10);
    box-sizing: border-box;
    text-transform: capitalize;
    svg {
      padding: 0 toRem(5) 0 0;
      color: $color-LelyWhite;
    }
  }
  .table-level-warning,
  .table-level-non-critical {
    background: $color-BrandWarning;
  }

  .table-level-debug,
  .table-level-event {
    background: grey;
  }

  .table-level-alarm,
  .table-level-error,
  .table-level-critical,
  .table-level-emergency {
    background: $color-BrandDanger;
  }

  .table-level-info,
  .table-level-information {
    background: $color-LelyPetrol;
  }

  .col-date-and-type {
    color: $color-gray-lighter;
  }

  // for mobile; hide table header any time
  thead {
    th[sortable] {
      cursor: auto;
    }

    th.checkbox-cell {
      padding-top: 0;

      .custom-control-label {
        position: absolute;
        top: 0;
        width: toRem(175);
      }
    }

    th:not(.checkbox-cell) {
      display: table-cell;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: top;
      text-align: inherit;
      padding: 0.75rem;
      min-width: toRem(50);
      word-break: break-all;

      // reset the following selector in forms.scss
      // select + .btn, select.custom-select + .btn, select.form-control + .btn
      .btn {
        min-width: initial;
        margin-left: 0;

        &.btn-icon {
          top: toRem(-4);
        }
      }

      //override some default styles
      .btn-sort {
        display: inline-block;
        margin-left: 0;

        &:before,
        &:after {
          display: block;
        }
      }

      &:not(.desc):before {
        opacity: 1;
      }

      &.asc {
        .btn-sort:before {
          opacity: 1;
        }
      }

      &.desc {
        .btn-sort:after {
          opacity: 1;
        }
      }
    }
  }

  tr,
  thead {
    -webkit-transition: 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
    transition: all 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
    td,
    th {
      padding: 8px 16px;
      //Automaticly wrap text in cells
      white-space: pre-wrap;
      vertical-align: middle;
      border: 0px;
      //Modifier class for text on one line
      &.nowrap,
      [nowrap] {
        white-space: nowrap;
      }

      &.checkbox-cell {
        padding-right: 0;
        width: toRem(24);
      }

      &.input-cell {
        padding-top: toRem(10);
        padding-bottom: toRem(10);
        height: toRem(60);
      }

      &.options-cell {
        width: 20px;
      }

      &:first-child {
        padding-left: toRem(16);
      }

      &:last-child {
        padding-right: 13px;
      }

      &.sticky-end {
        position: sticky;
        display: inline-block;
        right: 0;
        width: 20px;
        z-index: 1;
        box-shadow: -4px 0px 4px $color-GrayDairyLighter;
        width: 40px;
        padding-left: 0px;
      }

      &.th__colspan {
        padding-left: 0px;
        padding-right: 0px;
        position: relative;

        &:before,
        &:after {
          content: '';
          width: 1px;
          height: 5px;
          position: absolute;
          background: $color-GrayDairy;
          left: 0;
          top: 6px;
        }

        &:after {
          right: 0;
          left: auto;
        }

        span {
          color: $color-GrayDairyDarker;
          @include flexItemsCenter();

          &:before,
          &:after {
            content: '';
            height: 1px;
            background: $color-GrayDairy;
            flex: 1;
            position: relative;
            width: 100%;
            display: block;
            transform: translateY(8px);
          }

          &:before {
            margin-right: $spacing-between-containers;
          }

          &:after {
            margin-left: 8px;
          }
        }
      }

      .btn {
        background: 0 0;
        border-radius: 0;
        padding: 0;
        box-shadow: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        height: 40px;
        border: 0;
        vertical-align: sub;
        i {
          color: $color-gray-arrow;
        }

        &.btn-sort {
          height: auto;
        }
      }

      .btn:not(.btn-circle) {
        i[class^='lely-icon-'],
        i[class*=' lely-icon-'] {
          position: static;
          line-height: 38px;
        }
      }

      i[class^='lely-icon-'],
      i[class*=' lely-icon-'] {
        font-size: $font-xl;
        position: relative;
        transform: translateY(2px);
        -webkit-transform: translateY(3px);
        -moz-transform: translateY(1px);
      }

      .custom-control.custom-checkbox--star {
        align-items: center;
      }
      //spacing between icon and range slider on Health Report
      .icon {
        float: left;
        margin-right: 10px;
      }

      a {
        color: $default-text-color;
        text-decoration: none; // todo: discuss; remove text decoration for all links?
      }

      .label + .label {
        //e.g used in report overview
        margin-left: 10px;
      }

      .cell-meta {
        display: flex;
        flex-wrap: wrap;
        color: $color-GrayDairyDarker;
        font-weight: $font-regular;
        font-size: $font-base;
        line-height: $font-base-lh;
        margin: 1px 0 0;

        &__text {
          @include flexItemsCenter();
        }

        .rangeslider {
          margin-top: 15px;
          padding-top: 0;
          border: none;
        }
      }

      .cell-meta__text {
        &:after {
          content: '•';
          color: $color-GrayDairyLight;
          display: inline-block;
          margin: 0 5px;
        }

        &:last-of-type:after {
          content: none;
        }
      }
    }

    td {
      background: $color-LelyWhite;
      position: relative;
      min-height: 60px;
      padding: 0 0.75rem;
      box-sizing: border-box;

      &.d-md-table-cell {
        display: table-cell;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-of-type {
        //use last-of-type instead of last-child. Sometimes last child is an btn (see Manage feed)
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &.checkbox-cell {
        padding-right: 0;
        width: 24px;
        padding-top: 10px;
        padding-bottom: 10px;

        .custom-checkbox {
          margin: 6px 0px; //position to middle of row
        }
      }

      &.sticky-end {
        background-color: $color-LelyWhite;
      }

      input + span {
        margin-left: 10px;
      }

      span + input {
        margin-left: 10px;
      }

      strong + span {
        margin-left: 10px;
      }

      .tr-status {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;

        &--critical {
          border-left: 5px solid $color-BrandDanger;
        }

        &--warning {
          border-left: 5px solid $color-BrandWarning;
        }

        &--advice {
          border-left: 5px solid $color-BrandInfo;
        }
      }
    }

    th {
      padding-bottom: 8px 16px;
      i {
        font-size: $font-xl !important;
        line-height: $font-xl-lh !important;
        vertical-align: inherit;
      }
      &.sticky-end {
        background-color: $color-GrayDairyLighter;
      }
    }

    &:not(.individual-task) {
      td:last-child {
        .table--cell {
          padding-left: 13px;

          &:before {
            content: '';
            position: absolute;
            width: 1px;
            top: 15px;
            bottom: 15px;
            left: 0;
            background: $color-GrayDairy;
          }
        }
      }
    }

    &.table__divider {
      box-shadow: none;

      td {
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        background: transparent;
      }
    }
  }

  tbody {
    tr {
      border-radius: $border-radius; // do not remove this; necessary for using box shadow
      box-shadow: $box-shadow;
      -webkit-transition: 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
      transition: all 0.4s cubic-bezier(0.36, 0.03, 0.28, 0.99);
      &:hover td {
        background: #fff1ef;
        transition: background-color 0.3s ease;
      }

      &.tr-spacer {
        box-shadow: none;

        td {
          border: 0;
        }
      }
    }
    //Styling for collapsing table rows (see Manage Feed)
    &.tr-collapse-row {
      tr {
        td:not(:first-child) {
          padding-left: 35px;
        }
      }
    }

    &.tr-collapse-row,
    &.tr-collapse-body {
      tr {
        position: relative;

        &.actions-row {
          .actions {
            margin-right: 10px;
          }
        }

        .btn-collapse {
          position: absolute;
          z-index: 1;
          left: auto;
          right: 20px;
          transform: translateY(15px);
        }

        td {
          //Dont position absolute when collapse btn is in table cell
          .btn-collapse {
            position: relative;
            top: auto;
            right: auto;
            transform: none;
          }
        }
      }

      + tbody tr.table__divider td {
        height: 15px;
        padding: 0;
      }
    }

    &.tr-collapse-body {
      //Create indenting
      transform: translateX(5px);

      tr {
        box-shadow: none; // remove shadow for now because of the indenting (strange behaviour)
        td:last-of-type:not([colspan]) {
          //Remove extra width from last table cell for indenting
          width: calc(100% - 10px);
          display: block;
        }
        //All strong tags in the indented table rows have a medium weight.
        strong {
          font-weight: $font-medium;
        }

        .btn-collapse {
          top: 0;
          right: 40px;
        }
      }
    }
    //Create indenting for second level (see Feed table)
    + .tr-collapse-body--second {
      transform: translateX(40px);

      tr {
        td:last-of-type {
          //Remove extra width from last table cell for indenting
          width: calc(100% - 40px);
        }
      }
    }
  }

  .lely-icon-trash {
    font-size: 24px !important;
    transform: translateY(0px) !important;
    -webkit-transform: translateY(0px) !important;
  }
}

.MuiTablePagination-root {
  color: $color-GrayDairyDarker;
  .MuiIconButton-root {
    padding: 0px;
  }
  .MuiTablePagination-toolbar {
    min-height: 20px;
  }
  .MuiTablePagination-actions {
    margin-left: 10px;
  }
  .MuiTablePagination-selectRoot {
    margin-left: 0px;
    margin-right: 15px;
  }
}

.MuiButton-root {
  &.action-button {
    margin-right: 10px;
  }
  &.MuiContainer-maxWidthLg {
    max-width: 100%;
  }
}

.table-responsive {
  .table {
    position: static; //overwrite, relative on default breaks the sticky columns
    //Automaticly wrap text in table responsive cells
    td,
    th {
      white-space: nowrap;
    }
  }

  $sticky-column-width: 200px;

  .sticky-column {
    position: absolute;
    display: inline-block;
    background-color: $color-LelyWhite;
    width: $sticky-column-width;
    z-index: 1;
    box-shadow: 4px 0px 4px $color-GrayDairyLighter;
    padding-top: 20px;
    padding-bottom: 2px; // To hide the border
  }

  th.sticky-column {
    background-color: $color-GrayDairyLighter;
  }

  .sticky-end {
    position: sticky;
    display: inline-block;
    right: 0;
    background-color: $color-LelyWhite;
    width: 20px;
    z-index: 1;
    box-shadow: -4px 0px 4px $color-GrayDairyLighter;
    padding-top: 20px;
  }

  .sticky-column + td,
  .sticky-column + th {
    padding-left: calc(#{$sticky-column-width} + 20px);
  }
}
