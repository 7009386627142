@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: Ubuntu, sans-serif !important;
}

body.mozilla-scrollbar {
  * {
    scrollbar-width: thin;
    scrollbar-color: #8f8b8b #cbc6c5;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background: #8f8b8b;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cbc6c5;
  border-radius: 0.25rem;
}

.btn-secondary {
  cursor: pointer;
}

.btn-primary {
  cursor: pointer;
}
