@import '../../common/styles/variables.scss';
@import '../../common/styles/typography.scss';
@import '../../common/styles/functions.scss';
@import '../../common/styles/mixins.scss';

.farm-clock {
  @include flexRowItemsCenter();
  font-weight: 400;
  font-size: $font-base;
  font-family: inherit;
  color: $text-black-color;
  white-space: nowrap;
  height: 100%;

  span + time {
    margin-left: toRem(8);
  }
}
