@forward '@lely/icons/index';

@font-face {
  font-family: 'icons';
  src: url('./fonts/icons.eot') format('embedded-opentype'),
    url('./fonts/icons.woff2') format('woff2'),
    url('./fonts/icons.woff') format('woff'),
    url('./fonts/icons.ttf') format('truetype'),
    url('./fonts/icons.svg') format('svg');
}

i[class^='lely-icon-']:before,
i[class*=' lely-icon-']:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lely-icon-3g-mobiledata:before {
  content: '\ea01';
}
.lely-icon-4g-mobiledata:before {
  content: '\ea02';
}
.lely-icon-5g-mobiledata:before {
  content: '\ea03';
}
.lely-icon-abortion:before {
  content: '\ea04';
}
.lely-icon-account-balance:before {
  content: '\ea05';
}
.lely-icon-account:before {
  content: '\ea06';
}
.lely-icon-activities:before {
  content: '\ea07';
}
.lely-icon-add-file:before {
  content: '\ea08';
}
.lely-icon-alarm:before {
  content: '\ea09';
}
.lely-icon-apps:before {
  content: '\ea0a';
}
.lely-icon-archive:before {
  content: '\ea0b';
}
.lely-icon-arrow-down:before {
  content: '\ea0c';
}
.lely-icon-arrow-left:before {
  content: '\ea0d';
}
.lely-icon-arrow-right:before {
  content: '\ea0e';
}
.lely-icon-arrow-up:before {
  content: '\ea0f';
}
.lely-icon-astronaut:before {
  content: '\ea10';
}
.lely-icon-backup:before {
  content: '\ea11';
}
.lely-icon-bar-chart:before {
  content: '\ea12';
}
.lely-icon-bar-graph:before {
  content: '\ea13';
}
.lely-icon-barn:before {
  content: '\ea14';
}
.lely-icon-battery-1-bar:before {
  content: '\ea15';
}
.lely-icon-battery-2-bar:before {
  content: '\ea16';
}
.lely-icon-battery-charging:before {
  content: '\ea17';
}
.lely-icon-battery-full:before {
  content: '\ea18';
}
.lely-icon-block:before {
  content: '\ea19';
}
.lely-icon-bluetooth-connected:before {
  content: '\ea1a';
}
.lely-icon-bluetooth-disabled:before {
  content: '\ea1b';
}
.lely-icon-bluetooth-searching:before {
  content: '\ea1c';
}
.lely-icon-bluetooth:before {
  content: '\ea1d';
}
.lely-icon-bug-report:before {
  content: '\ea1e';
}
.lely-icon-calendar:before {
  content: '\ea1f';
}
.lely-icon-call:before {
  content: '\ea20';
}
.lely-icon-card-view:before {
  content: '\ea21';
}
.lely-icon-caret-down:before {
  content: '\ea22';
}
.lely-icon-caret-left:before {
  content: '\ea23';
}
.lely-icon-caret-right:before {
  content: '\ea24';
}
.lely-icon-caret-up:before {
  content: '\ea25';
}
.lely-icon-cash:before {
  content: '\ea26';
}
.lely-icon-category:before {
  content: '\ea27';
}
.lely-icon-central-unit:before {
  content: '\ea28';
}
.lely-icon-charging-station:before {
  content: '\ea29';
}
.lely-icon-chart:before {
  content: '\ea2a';
}
.lely-icon-chat-bubble:before {
  content: '\ea2b';
}
.lely-icon-check:before {
  content: '\ea2c';
}
.lely-icon-checklist:before {
  content: '\ea2d';
}
.lely-icon-chevron-left:before {
  content: '\ea2e';
}
.lely-icon-chevron-right:before {
  content: '\ea2f';
}
.lely-icon-circle-check:before {
  content: '\ea30';
}
.lely-icon-clock-interval:before {
  content: '\ea31';
}
.lely-icon-clock:before {
  content: '\ea32';
}
.lely-icon-close:before {
  content: '\ea33';
}
.lely-icon-cloud-backup:before {
  content: '\ea34';
}
.lely-icon-cloud-done:before {
  content: '\ea35';
}
.lely-icon-cloud-download:before {
  content: '\ea36';
}
.lely-icon-cloud:before {
  content: '\ea37';
}
.lely-icon-comment:before {
  content: '\ea38';
}
.lely-icon-computer:before {
  content: '\ea39';
}
.lely-icon-copy:before {
  content: '\ea3a';
}
.lely-icon-cow:before {
  content: '\ea3b';
}
.lely-icon-creditcard:before {
  content: '\ea3c';
}
.lely-icon-dashboard:before {
  content: '\ea3d';
}
.lely-icon-data-points:before {
  content: '\ea3e';
}
.lely-icon-diagram:before {
  content: '\ea3f';
}
.lely-icon-disease:before {
  content: '\ea40';
}
.lely-icon-dissatisfied:before {
  content: '\ea41';
}
.lely-icon-document:before {
  content: '\ea42';
}
.lely-icon-download-done:before {
  content: '\ea43';
}
.lely-icon-download:before {
  content: '\ea44';
}
.lely-icon-drag:before {
  content: '\ea45';
}
.lely-icon-driver:before {
  content: '\ea46';
}
.lely-icon-dry-off:before {
  content: '\ea47';
}
.lely-icon-east:before {
  content: '\ea48';
}
.lely-icon-edit:before {
  content: '\ea49';
}
.lely-icon-emotions:before {
  content: '\ea4a';
}
.lely-icon-engine:before {
  content: '\ea4b';
}
.lely-icon-error:before {
  content: '\ea4c';
}
.lely-icon-export:before {
  content: '\ea4d';
}
.lely-icon-farm:before {
  content: '\ea4e';
}
.lely-icon-feed:before {
  content: '\ea4f';
}
.lely-icon-fence:before {
  content: '\ea50';
}
.lely-icon-fetch-cow:before {
  content: '\ea51';
}
.lely-icon-filter:before {
  content: '\ea52';
}
.lely-icon-fingerprint:before {
  content: '\ea53';
}
.lely-icon-folder-open:before {
  content: '\ea54';
}
.lely-icon-folder-zip:before {
  content: '\ea55';
}
.lely-icon-fullscreen-exit:before {
  content: '\ea56';
}
.lely-icon-fullscreen:before {
  content: '\ea57';
}
.lely-icon-grabber:before {
  content: '\ea58';
}
.lely-icon-graph-down:before {
  content: '\ea59';
}
.lely-icon-graph-up:before {
  content: '\ea5a';
}
.lely-icon-graph-view:before {
  content: '\ea5b';
}
.lely-icon-graph:before {
  content: '\ea5c';
}
.lely-icon-grass:before {
  content: '\ea5d';
}
.lely-icon-grid-view:before {
  content: '\ea5e';
}
.lely-icon-grid:before {
  content: '\ea5f';
}
.lely-icon-group-add:before {
  content: '\ea60';
}
.lely-icon-group-remove:before {
  content: '\ea61';
}
.lely-icon-group:before {
  content: '\ea62';
}
.lely-icon-health-report:before {
  content: '\ea63';
}
.lely-icon-health-treatment:before {
  content: '\ea64';
}
.lely-icon-heat:before {
  content: '\ea65';
}
.lely-icon-help:before {
  content: '\ea66';
}
.lely-icon-herd-movement:before {
  content: '\ea67';
}
.lely-icon-herd:before {
  content: '\ea68';
}
.lely-icon-hide:before {
  content: '\ea69';
}
.lely-icon-history:before {
  content: '\ea6a';
}
.lely-icon-home:before {
  content: '\ea6b';
}
.lely-icon-hot-rins:before {
  content: '\ea6c';
}
.lely-icon-human:before {
  content: '\ea6d';
}
.lely-icon-income:before {
  content: '\ea6e';
}
.lely-icon-information:before {
  content: '\ea6f';
}
.lely-icon-insemination:before {
  content: '\ea70';
}
.lely-icon-iris-group:before {
  content: '\ea71';
}
.lely-icon-iris:before {
  content: '\ea72';
}
.lely-icon-keyboard:before {
  content: '\ea73';
}
.lely-icon-language:before {
  content: '\ea74';
}
.lely-icon-laptop:before {
  content: '\ea75';
}
.lely-icon-layers-clear:before {
  content: '\ea76';
}
.lely-icon-layers:before {
  content: '\ea77';
}
.lely-icon-link:before {
  content: '\ea78';
}
.lely-icon-list-bulletin:before {
  content: '\ea79';
}
.lely-icon-list:before {
  content: '\ea7a';
}
.lely-icon-locate:before {
  content: '\ea7b';
}
.lely-icon-location:before {
  content: '\ea7c';
}
.lely-icon-lock:before {
  content: '\ea7d';
}
.lely-icon-login:before {
  content: '\ea7e';
}
.lely-icon-logout:before {
  content: '\ea7f';
}
.lely-icon-m4use:before {
  content: '\ea80';
}
.lely-icon-mail:before {
  content: '\ea81';
}
.lely-icon-manage:before {
  content: '\ea82';
}
.lely-icon-manure:before {
  content: '\ea83';
}
.lely-icon-map:before {
  content: '\ea84';
}
.lely-icon-medicine:before {
  content: '\ea85';
}
.lely-icon-menu:before {
  content: '\ea86';
}
.lely-icon-mfr-dosing-roller:before {
  content: '\ea87';
}
.lely-icon-mfr-feed-door-closing:before {
  content: '\ea88';
}
.lely-icon-mfr-feed-door-opening:before {
  content: '\ea89';
}
.lely-icon-mfr-open:before {
  content: '\ea8a';
}
.lely-icon-mfr:before {
  content: '\ea8b';
}
.lely-icon-microphone:before {
  content: '\ea8c';
}
.lely-icon-milk-seperation:before {
  content: '\ea8d';
}
.lely-icon-milk:before {
  content: '\ea8e';
}
.lely-icon-minus:before {
  content: '\ea8f';
}
.lely-icon-mixer-motor:before {
  content: '\ea90';
}
.lely-icon-more-horizontal:before {
  content: '\ea91';
}
.lely-icon-more-vertical:before {
  content: '\ea92';
}
.lely-icon-mouse:before {
  content: '\ea93';
}
.lely-icon-network-wifi:before {
  content: '\ea94';
}
.lely-icon-neutral:before {
  content: '\ea95';
}
.lely-icon-north-east:before {
  content: '\ea96';
}
.lely-icon-north-west:before {
  content: '\ea97';
}
.lely-icon-north:before {
  content: '\ea98';
}
.lely-icon-notification-mute:before {
  content: '\ea99';
}
.lely-icon-notification-on:before {
  content: '\ea9a';
}
.lely-icon-notification:before {
  content: '\ea9b';
}
.lely-icon-notifications:before {
  content: '\ea9c';
}
.lely-icon-objects:before {
  content: '\ea9d';
}
.lely-icon-ongoing:before {
  content: '\ea9e';
}
.lely-icon-opportunities:before {
  content: '\ea9f';
}
.lely-icon-pains:before {
  content: '\eaa0';
}
.lely-icon-pan:before {
  content: '\eaa1';
}
.lely-icon-panel:before {
  content: '\eaa2';
}
.lely-icon-pause:before {
  content: '\eaa3';
}
.lely-icon-pdb:before {
  content: '\eaa4';
}
.lely-icon-pending:before {
  content: '\eaa5';
}
.lely-icon-person-approve:before {
  content: '\eaa6';
}
.lely-icon-person-assign:before {
  content: '\eaa7';
}
.lely-icon-person-remove:before {
  content: '\eaa8';
}
.lely-icon-person-unassign:before {
  content: '\eaa9';
}
.lely-icon-person:before {
  content: '\eaaa';
}
.lely-icon-phone:before {
  content: '\eaab';
}
.lely-icon-picture:before {
  content: '\eaac';
}
.lely-icon-pinc-zoom-out:before {
  content: '\eaad';
}
.lely-icon-pinch-zoom-in:before {
  content: '\eaae';
}
.lely-icon-play:before {
  content: '\eaaf';
}
.lely-icon-plus:before {
  content: '\eab0';
}
.lely-icon-power-management:before {
  content: '\eab1';
}
.lely-icon-power:before {
  content: '\eab2';
}
.lely-icon-pregnancy-check:before {
  content: '\eab3';
}
.lely-icon-pregnancy:before {
  content: '\eab4';
}
.lely-icon-printer:before {
  content: '\eab5';
}
.lely-icon-qr-code-scanner:before {
  content: '\eab6';
}
.lely-icon-qr-code:before {
  content: '\eab7';
}
.lely-icon-redo:before {
  content: '\eab8';
}
.lely-icon-refresh:before {
  content: '\eab9';
}
.lely-icon-reminder:before {
  content: '\eaba';
}
.lely-icon-reports:before {
  content: '\eabb';
}
.lely-icon-reproduction-check:before {
  content: '\eabc';
}
.lely-icon-reset:before {
  content: '\eabd';
}
.lely-icon-robot:before {
  content: '\eabe';
}
.lely-icon-rotate-left:before {
  content: '\eabf';
}
.lely-icon-rotate-map:before {
  content: '\eac0';
}
.lely-icon-rotate-right:before {
  content: '\eac1';
}
.lely-icon-route-layers:before {
  content: '\eac2';
}
.lely-icon-routes:before {
  content: '\eac3';
}
.lely-icon-routing:before {
  content: '\eac4';
}
.lely-icon-satisfied:before {
  content: '\eac5';
}
.lely-icon-scanner:before {
  content: '\eac6';
}
.lely-icon-scatterplot:before {
  content: '\eac7';
}
.lely-icon-screen-rotation:before {
  content: '\eac8';
}
.lely-icon-search:before {
  content: '\eac9';
}
.lely-icon-send:before {
  content: '\eaca';
}
.lely-icon-sensor:before {
  content: '\eacb';
}
.lely-icon-settings:before {
  content: '\eacc';
}
.lely-icon-shopping-cart:before {
  content: '\eacd';
}
.lely-icon-show:before {
  content: '\eace';
}
.lely-icon-signal-cellular-0-bar:before {
  content: '\eacf';
}
.lely-icon-signal-cellular-2-bar:before {
  content: '\ead0';
}
.lely-icon-signal-cellular-4-bar:before {
  content: '\ead1';
}
.lely-icon-signal-cellular-off:before {
  content: '\ead2';
}
.lely-icon-signal-wifi-bad:before {
  content: '\ead3';
}
.lely-icon-signal-wifi-off:before {
  content: '\ead4';
}
.lely-icon-sires:before {
  content: '\ead5';
}
.lely-icon-sort-disabled:before {
  content: '\ead6';
}
.lely-icon-sort-down:before {
  content: '\ead7';
}
.lely-icon-sort-up:before {
  content: '\ead8';
}
.lely-icon-sort:before {
  content: '\ead9';
}
.lely-icon-south-east:before {
  content: '\eada';
}
.lely-icon-south-west:before {
  content: '\eadb';
}
.lely-icon-south:before {
  content: '\eadc';
}
.lely-icon-stacked-bar-chart:before {
  content: '\eadd';
}
.lely-icon-star-empty:before {
  content: '\eade';
}
.lely-icon-star-filled:before {
  content: '\eadf';
}
.lely-icon-stop:before {
  content: '\eae0';
}
.lely-icon-swipe-down:before {
  content: '\eae1';
}
.lely-icon-swipe-left-and-right:before {
  content: '\eae2';
}
.lely-icon-swipe-left:before {
  content: '\eae3';
}
.lely-icon-swipe-up:before {
  content: '\eae4';
}
.lely-icon-table-row:before {
  content: '\eae5';
}
.lely-icon-table:before {
  content: '\eae6';
}
.lely-icon-tablet:before {
  content: '\eae7';
}
.lely-icon-tasks:before {
  content: '\eae8';
}
.lely-icon-thermostat:before {
  content: '\eae9';
}
.lely-icon-thumb-down:before {
  content: '\eaea';
}
.lely-icon-thumb-up:before {
  content: '\eaeb';
}
.lely-icon-touch:before {
  content: '\eaec';
}
.lely-icon-traffic:before {
  content: '\eaed';
}
.lely-icon-transfer:before {
  content: '\eaee';
}
.lely-icon-trash:before {
  content: '\eaef';
}
.lely-icon-tv:before {
  content: '\eaf0';
}
.lely-icon-undo:before {
  content: '\eaf1';
}
.lely-icon-update:before {
  content: '\eaf2';
}
.lely-icon-upload:before {
  content: '\eaf3';
}
.lely-icon-valve:before {
  content: '\eaf4';
}
.lely-icon-version:before {
  content: '\eaf5';
}
.lely-icon-very-dissatisfied:before {
  content: '\eaf6';
}
.lely-icon-very-satisfied:before {
  content: '\eaf7';
}
.lely-icon-view-list:before {
  content: '\eaf8';
}
.lely-icon-warning:before {
  content: '\eaf9';
}
.lely-icon-watch:before {
  content: '\eafa';
}
.lely-icon-water:before {
  content: '\eafb';
}
.lely-icon-west:before {
  content: '\eafc';
}
.lely-icon-wifi-off:before {
  content: '\eafd';
}
.lely-icon-wifi:before {
  content: '\eafe';
}
.lely-icon-young-stock:before {
  content: '\eaff';
}
.lely-icon-zoom-in:before {
  content: '\eb00';
}
.lely-icon-zoom-out:before {
  content: '\eb01';
}
