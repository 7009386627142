@import '../../common/styles/variables.scss';
@import '../../common/styles/functions.scss';
@import '../../common/styles/tableMixins.scss';
@import '../../common/styles/typography.scss';

.user-dialog-wrapper {
  .user-dialog-title {
    color: $color-LelyWhite;
    font-family: $font-DefaultFont;
    font-size: toRem(21);
    font-style: normal;
    font-weight: 300;
    display: inline-flex;
    justify-content: space-between;
    svg {
      color: $color-LelyWhite;
      cursor: pointer;
    }
  }
  .MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 0 toRem(16) toRem(16);
    button {
      display: flex;
      height: toRem(40);
      padding: toRem(12) toRem(15);
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      text-transform: none;
    }
  }
  &.create-backup {
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
      height: toRem(186);
      width: toRem(400);
    }
  }
  &.restore-backup {
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
      height: toRem(234);
      width: toRem(400);
    }
  }
  &.restore-table-dailog {
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
      height: toRem(500);
      width: auto;
      max-width: toRem(900);
    }
    .MuiDialogContent-root.user-dialog-content {
      padding: 0;
      .table {
        margin: 0;
        thead {
          tr {
            th {
              &:nth-child(1) {
                width: toRem(200);
                max-width: toRem(200);
              }
              &:nth-child(5) {
                visibility: hidden;
                width: toRem(100);
                max-width: toRem(100);
              }
            }
          }
        }
        tbody {
          max-height: 100%;
          tr {
            td {
              height: toRem(56);
              button {
                text-transform: none;
                padding: toRem(12) toRem(15);
                line-height: toRem(16);
              }
            }
            td:nth-child(1) {
              width: toRem(200);
              max-width: toRem(200);
            }

            td:nth-child(5) {
              padding: toRem(8) 0;
              width: toRem(100);
              max-width: toRem(100);
            }
          }
        }
      }
    }
  }

  .MuiDialogContent-root.user-dialog-content {
    padding: 0;
    margin: toRem(16);
    font-family: $font-DefaultFont;
    p {
      white-space: pre-wrap;
      color: $color-LelyBlack;
      font-style: normal;
      font-weight: 400;
    }
  }
  .MuiDialogContent-root.user-dialog-content:has(.table) {
    margin: 0;
  }
}

.logs__download-dialog {
  .user-dialog-buttons {
    .dialog-buttons {
      max-width: fit-content;
    }
  }
}
