@import '../../common/styles/variables.scss';
@import '../../common/styles/functions.scss';
@import '../../common/styles/mixins.scss';
@import '../../common/styles/typography.scss';

.LoginPage {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-size: contain;
  background-position-x: right;
  background-repeat: no-repeat;
  background-color: $color-LelyRed;

  .MuiTextField-root,
  .MuiFormControl-root {
    @include baseCard();
    width: toRem(280);
    .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl:after {
      border-bottom: 0 solid !important;
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
      -webkit-transition: none !important;
      transition: none !important;
    }
    .Mui-focused {
      color: rgba(0, 0, 0, 0.87) !important;
      &::after {
        border-bottom: 0 solid !important;
        -webkit-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
        -webkit-transition: none !important;
        transition: none !important;
      }
    }
  }

  .MuiFormControl-root {
    margin: toRem(16) 0 0;
  }

  .MuiContainer-root {
    margin-left: toRem(135);
    padding-top: 13rem;
  }

  .MuiButton-root {
    width: toRem(280);
    background-color: #850c18 !important;
    color: $color-LelyWhite !important;
    margin-top: toRem(16);
    font-size: $font-base;
    padding: toRem(8) toRem(16);

    &.azure-login {
      height: toRem(45);
      background-color: transparent !important;
      border: toRem(1) solid $color-LelyWhite;
      line-height: normal;
      margin: 0;

      svg {
        margin-right: toRem(10);
      }
    }
  }

  .MuiPaper-elevation {
    .MuiTypography-root {
      color: $color-LelyWhite;
      font-family: Ubuntu;
    }

    padding-top: toRem(16);
    background-color: initial;
    box-shadow: none;
  }

  .logo-container {
    height: 3.8rem;
  }
}

@media only screen and (max-height: 481px) {
  form {
    .title {
      font-size: 1.5rem;
      padding: toRem(12) 0;
      width: toRem(250);
    }
  }
  .LoginPage .MuiContainer-root {
    margin-left: 2rem;
    padding-top: calc(100vh - 65%);
  }
  .delimeter {
    margin: 0.75rem 0;
    width: toRem(200);
  }
  .delimeter::after {
    width: toRem(270);
  }
}

@media only screen and (min-height: 482px) and (max-height: 580px) {
  form {
    .title {
      font-size: 1.5rem;
      padding: toRem(12) 0;
      width: toRem(270);
      padding-bottom: toRem(6);
    }
  }
  .LoginPage .MuiContainer-root {
    margin-left: 2rem;
    padding-top: 1rem;
  }
  .delimeter {
    margin: 0.75rem 0;
    width: toRem(200);
  }
  .delimeter::after {
    width: toRem(270);
  }
}

@media only screen and (min-height: 581px) and (max-height: 735px) {
  form {
    .title {
      font-size: 1.75rem;
      padding: toRem(12) 0;
      width: toRem(270);
    }
  }
  .LoginPage .MuiContainer-root {
    margin-left: 2rem;
    padding-top: 5rem;
  }
  .delimeter {
    margin: 0.75rem 0;
    width: toRem(200);
  }
  .delimeter::after {
    width: toRem(270);
  }
}
